.max-width {
  min-width: max-content !important;
}

.table th {
  max-width: 100%;
  white-space: nowrap;
}

.table td {
  max-width: 100%;
  white-space: nowrap;
}

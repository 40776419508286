.details-row {
  transition: height 0.3s ease; /* Add transition effect */
  overflow: hidden; /* Hide overflow */
}

.details-row .card {
  border: none; /* Remove card border */
  box-shadow: none; /* Remove card shadow */
}

.details-row .table {
  margin-bottom: 0; /* Remove bottom margin of child table */
}

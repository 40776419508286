/* DashboardLayout.css */
.main-container {
  padding-top: 6rem;
  background-color: rgb(246, 246, 246);
  min-height: 100vh;
  height: max-content;
}
.sidebar {
  position: fixed;
  top: 0;
  left: -250px; /* Hide sidebar initially */
  width: 250px;
  height: 100%;
  background: #fffafa;
  transition: left 0.5s;
  z-index: 1999;
  border-right: 1px solid #2125292e;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.sidebar.open {
  left: 0 !important;
}

.content {
  transition: margin-left 0.5s;
}

.content.open {
  margin-left: 250px;
}

.toggle-button {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 2;
}

.sidebar-content {
  padding-top: 3rem;
  color: black;
}

.sidebar-content h2 {
  margin-bottom: 20px;
}

.nav-link {
  padding: 10px 20px;
  color: black;
}

.nav-link:hover {
  background-color: #495057;
}
.header-content {
  width: 100%;
}

/*   Side Bar inner styling */

.sidebar-btn {
  background-color: transparent;
  width: 100%;
  height: 50px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
}
.sidebar-btn-selected {
  background-color: #212529;
  color: white;
  width: 100%;
  height: 50px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
}
.sidebar-btn:hover {
  background-color: #212529;
  color: white;
}
